<template>
  <div
    :style="style"
    class="overflow-hidden"
    v-bind="attr"
  >
    <div
      ref="refEl"
      class="tw-testimonials !-mr-8 flex snap-x snap-mandatory flex-nowrap overflow-auto will-change-scroll"
      :class="{ 'justify-center': mountedComp && !hasPaging }"
    >
      <Block
        v-for="(item, index) of testimonials"
        :key="index"
        class="tw-testimonials-block w-full flex-[100%_0_0] md:w-[50%] md:flex-[50%_0_0] lg:w-[calc(100%/3)] lg:flex-[calc(100%/3)_0_0]"
        :title="item.title"
        :desc="item.desc"
        :image="item.image"
        :options="element.options"
        :page-options="pageOptions"
        :style="element.style"
      />
    </div>

    <Transition mode="default" name="slide-up">
      <div
        v-if="hasPaging"
        class="flex items-center justify-center gap-8 pt-16"
      >
        <span
          v-for="index of paging.total"
          :key="index - 1"
          class="block h-[8px] w-[8px] cursor-pointer rounded-full"
          :style="pagingStyle"
          :class="{ 'opacity-20': index - 1 !== paging.current }"
          @click="goToPage(index - 1)"
        ></span>
      </div>
    </Transition>
  </div>
</template>

<script lang="ts" setup>
import { type PropType, computed, onBeforeUnmount, onMounted, ref, watch } from 'vue';
import type { Element as ElementType, PageOptions } from '@shared/types/model';
import type { ElementOptions } from '@shared/types/options';
import { debounce } from '@shared/utils/helpers';
import Block from './components/block.vue';

const props = defineProps({
  style: { type: Object, default: () => ({}) },
  attr: { type: Object as PropType<ElementType<'testimonials'>['attr']>, default: () => ({}) },
  element: { type: Object as PropType<ElementType<'testimonials'>>, default: () => ({}) },
  config: { type: Object as PropType<ElementOptions['testimonials']>, default: () => ({}) },
  isEditorMode: { type: Boolean, default: false },
  pageOptions: { type: Object as PropType<PageOptions>, default: () => ({}) }
});

const refEl = ref<HTMLElement | undefined>();
const paging = ref({
  total: 0,
  current: 0,
});
const mountedComp = ref(false);
const slideInterval = ref();
const hasPaging = computed(() => paging.value.total > 1);
const values = computed(() => JSON.parse(props.element.values || '{}'));
const testimonials = computed(() =>
  values.value.testimonials.slice(0, props.config.count?.current || props.config.count?.max)
);
const total = computed(() => {
  return testimonials.value.length || 2;
});

const pagingStyle = computed(() => {
  return {
    backgroundColor: props.pageOptions.colors.theme[0]
  }
});

watch(
  () => total.value,
  () => {
    refreshPaging();
    setTimeout(() => {
      scrollElement(0, 'instant');
      paging.value.current = 0;
    }, 100);
  }
);

watch(
  () => props.config.slideDuration,
  () => resetAutoSlide()
)

function getVisibleElementSize(winW?: number) {
  winW = winW || window.innerWidth;

  if (winW >= 1024) return 3;
  if (winW >= 768) return 2;
  return 1;
}

function calculatePaging(winW?: number) {
  const visible = getVisibleElementSize(winW);
  return Math.ceil(total.value / visible);
}

function scrollElement(width: number, behavior?: 'auto' | 'instant' | 'smooth') {
  refEl.value?.scrollTo({
    left: width,
    behavior: behavior || 'smooth'
  });
}

function goToPage(index: number) {
  const visible = getVisibleElementSize();
  const scrollWidth = refEl.value?.scrollWidth || 0;
  const itemRateByScroll = 100 / total.value;
  const itemWidth = Math.ceil(scrollWidth / 100 * itemRateByScroll);
  let pageWidth = itemWidth * visible;

  if (document.dir === 'rtl') {
    pageWidth = -1 * pageWidth;
  }

  const scrollToWidth = index * pageWidth;

  paging.value.current = index;

  scrollElement(scrollToWidth);
}

function setPagingByScroll() {
  const visible = getVisibleElementSize();
  const scrollWidth = refEl.value?.scrollWidth || 0;
  const scrollLeft = Math.abs(refEl.value?.scrollLeft || 0);
  const itemRateByScroll = 100 / total.value;
  const itemWidth = Math.ceil(scrollWidth / 100 * itemRateByScroll);
  const pageWidth = itemWidth * visible;
  const currentPos = Math.ceil(scrollLeft / pageWidth);

  paging.value.current = currentPos;
}

function refreshPaging(winW?: number) {
  const prevTotal = paging.value.total;
  paging.value.total = calculatePaging(winW);

  if (prevTotal !== paging.value.total) {
    scrollElement(0);
    paging.value.current = 0;
  }
}

function nextSlide() {
  if (paging.value.current < paging.value.total - 1) {
    goToPage(paging.value.current + 1);
  } else {
    goToPage(0);
  }
}

function startAutoSlide() {
  const duration = ((props.config.slideDuration || 5) * 1000);

  slideInterval.value = setInterval(() => {
    nextSlide()
  }, duration);
}

function stopAutoSlide() {
  clearInterval(slideInterval.value);
}

function resetAutoSlide() {
  stopAutoSlide();
  startAutoSlide();
}

const onResize = debounce(() => refreshPaging(), 200);
const onScroll = debounce(() => setPagingByScroll(), 200);

onMounted(() => {
  refreshPaging();
  mountedComp.value = true;
  if (!props.isEditorMode) {
    window.addEventListener('resize', onResize);
    refEl.value?.addEventListener('scroll', onScroll);
    resetAutoSlide();
  } else {
    const screenEl = document.querySelector('.tw-editor__screen');
    if (screenEl) {
      new ResizeObserver(function() {
        refreshPaging(screenEl.clientWidth);
      }).observe(screenEl)
    }
  }
});

onBeforeUnmount(() => {
  if (!props.isEditorMode) {
    window.removeEventListener('resize', onResize);
    refEl.value?.removeEventListener('scroll', onScroll);
  }
});
</script>

<style lang="postcss">
  .tw-testimonials {
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }

    &-block {
      @apply snap-start;
    }
  }
  
  .slide-up {
    &-enter-active,
    &-leave-active {
      transition: all 0.15s ease-out;
    }
    &-enter-from {
      opacity: 0;
      margin-top: -24px;
    }

    &-leave-to {
      opacity: 0;
      margin-top: -24px;
    }
  }

  .tablet {
    .tw-testimonials-block {
      scroll-snap-type: none;
      scroll-snap-align: unset;

      &:nth-of-type(2n + 1) {
        scroll-snap-stop: always;
        scroll-snap-align: start;
      }
    }
  }

  .desktop {
    .tw-testimonials-block {
      scroll-snap-type: none;
      scroll-snap-align: unset;

      &:nth-of-type(3n + 1) {
        scroll-snap-stop: always;
        scroll-snap-align: start;
      }
    }
  }

  @media screen and (min-width: 768px) {
    .tw-testimonials-block {
      scroll-snap-type: none;
      scroll-snap-align: unset;

      &:nth-of-type(2n + 1) {
        scroll-snap-stop: always;
        scroll-snap-align: start;
      }
    }
  }

  @media screen and (min-width: 1024px) {
    .tw-testimonials-block {
      &:nth-of-type(2n + 1) {
        scroll-snap-type: none;
        scroll-snap-align: unset;
      }

      &:nth-of-type(3n + 1) {
        scroll-snap-stop: always;
        scroll-snap-align: start;
      }
    }
  }
</style>
